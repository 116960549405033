<template>
  <b-modal
    id="modal-deposit"
    hide-header
    hide-footer
    ok-only
    ok-title="Accept"
    centered
    size="lg"
    title="Large Modal"
    class="custom__modal--large"
  >
    <DetailTopup
      :title_header="'Topup'"
      :type_component="'topup'"
      :results="results"
      :getData="getData"
    />
  </b-modal>
</template>

<script>
import {
  BModal,
} from 'bootstrap-vue'

import DetailTopup from '@/components/Customer/Modal/Part/DetailCustomer.vue'

export default {
  components: {
    BModal,
    DetailTopup,
  },
  props: {
    results: {
      type: Object,
    },
    getData: {
      type: Function,
    },
  },
  data() {
    return {
    }
  },
  watch: {
  },
  methods: {
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/_variables.scss';
</style>
