<template>
  <div
    id="table-deposit"
    class="table__container"
  >
    <div class="transaction__filter--container bg-white py-2">
      <div class="d-flex align-items-center search__export w-100">
        <div class="d-flex pl-2">
          <b-img
            class="filter-image"
            :class="filter.sort_type == 'asc' ? 'rotate-180' : ''"
            :src="require('@/assets/images/icons/Icon-order-list-down.svg')"
            alt="Icon-order-list-down"
            role="button"
            @click="filter.sort_type == 'asc' ? filter.sort_type = 'desc' : filter.sort_type = 'asc'"
          />
        </div>
        <div class="search__input w-100 px-2">
          <b-input-group>
            <b-form-input
              v-model="filter.search"
              placeholder="Cari nama pelanggan"
            />
            <b-input-group-append is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>
    </div>

    <div
      v-if="isLoading == true"
      class="d-flex justify-content-center mb-1 mt-5"
    >
      <b-spinner label="Loading..." />
    </div>

    <vue-good-table
      v-else
      max-height="80vh"
      :columns="columns"
      :rows="rows.data"
      :fixed-header="false"
      :sort-options="{
        enabled: false
      }"
      :pagination-options="{
        enabled: false,
      }"
    >
      <template
        slot="table-column"
        slot-scope="props"
      >
        <span v-if="props.column.field == 'action'" />
      </template>
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Action -->
        <span v-if="props.column.field === 'wallet_balance'">
          <span>{{ props.row.wallet_balance | formatAmount }}</span>
        </span>
        
        <span v-else-if="props.column.field === 'id_card_number'">
          <span>{{ props.row.id_card_number || '-' }}</span>
        </span>

        <span v-else-if="props.column.field === 'action'">
          <div>
            <b-button
              class="bg-white border-8 px-3"
              @click="topup(props.row.uuid)"
            >
              Top Up
            </b-button>
          </div>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>

    <div
      v-if="rows && isLoading == false"
      class="d-flex justify-content-between align-items-center flex-wrap bg-white border"
      style="padding: 0.8rem;"
    >
      <div class="d-flex align-items-center">
        <b-form-select
          v-model="filter.per_page"
          :options="['10','15','20']"
          class="mx-1"
        />
        <span class="text-nowrap">
          Tampilkan {{ (rows.total > 0) ? (filter.per_page * (rows.current_page - 1)) + 1 : 0 }} ke
          {{ (rows.total > 0) ? Math.min(filter.per_page * rows.current_page, rows.total) : 0 }} dari {{ rows.total }} transaksi.
        </span>
      </div>
      <div>
        <b-row class="mx-0 align-items-center">
          <b-col>
            <pagination
              class="mb-0"
              :data="rows"
              :limit="4"
              align="right"
              @pagination-change-page="getData"
            />
          </b-col>
        </b-row>
      </div>
    </div>

    <ModalTopup
      :get-data="getData"
      :results="resultDetail"
    />
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from 'lodash'
import { errorNotification } from '@/auth/utils'
import {
  BPagination, BFormSelect, VBToggle, BFormInput, BInputGroup, BInputGroupAppend, BImg, BButton, BSpinner, BRow, BCol,
} from 'bootstrap-vue'

import ModalTopup from '@/components/Deposit/Modal/ModalTopup.vue'

import { VueGoodTable } from 'vue-good-table'

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormSelect,
    ModalTopup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BButton,
    BSpinner,
    BRow,
    BCol,
  },
  setup() {
    return {
      errorNotification,
    }
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      is_edit: false,
      isLoading: false,
      columns: [
        {
          label: 'Nama',
          field: 'name',
        },
        {
          label: 'No. Telepon',
          field: 'phone_number',
        },
        {
          label: 'No. KTP',
          field: 'id_card_number',
        },
        {
          label: 'Deposit',
          field: 'wallet_balance',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: {},
      filter: {
        search: '',
        per_page: 10,
        sort_type: 'desc',
      },
      resultDetail: {},
    }
  },
  watch: {
    filter: {
      handler: _.debounce(function () {
        this.getData()
      }, 300),
      deep: true,
    },
  },
  created() {
    this.getData()
  },
  methods: {
    topup(uuid) {
      localStorage.customer_uuid = uuid
      this.$store.dispatch('customer/getDetail', {
        uuid,
      }).then(result => {
        this.resultDetail = result.data.data
        this.$bvModal.show('modal-deposit')
      }).catch(err => {
        console.log(err)
      })
    },
    getData(page = 1) {
      this.isLoading = true
      const queryParams = this.filter
      queryParams.page = page
      queryParams.only_have_balance = 1
      this.$store.dispatch('customer/getData', {
        params: queryParams,
      }).then(result => {
        this.rows = result.data.data
        this.isLoading = false
      }).catch(err => {
        if (err.response.data.meta.messages) {
          errorNotification(this, 'Oops!', err.response.data.meta.messages)
        }
        this.isLoading = false
        console.log(err)
      })
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/_variables.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';

#table-deposit {
  .vgt-responsive {
    height: calc(100vh - 235px) !important;
    background-color: #fff;
  }
}
</style>
