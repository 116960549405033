<template>
  <div class="section">
    <header-page>
      <span class="text-dark fw-bold-700 size14">Deposit</span>
    </header-page>

    <keep-alive>
      <component :is="comp" />
    </keep-alive>
  </div>
</template>

<script>
import {
  BButton, BButtonGroup, BDropdown, BDropdownItem, BImg, BPagination, BFormSelect, BModal, VBToggle, BRow, BCol,
} from 'bootstrap-vue'

import HeaderPage from '@/components/HeaderPage.vue'
import TableDeposit from '@/components/Deposit/TableDeposit.vue'

export default {
  title() {
    return 'Deposit'
  },
  components: {
    BButton,
    HeaderPage,
    BButtonGroup,
    BDropdown,
    BDropdownItem,
    BImg,
    BPagination,
    BFormSelect,
    BModal,
    BRow,
    BCol,
    TableDeposit,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      comp: 'TableDeposit',
    }
  },
  computed: {
  },
  created() {
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/_variables.scss';

</style>
